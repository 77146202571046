<template>
  <div class="layout">
    <!-- <banner></banner> -->
    <hero></hero>
    <div class="layout-content">
      <showcase-site></showcase-site>
      <showcase-app></showcase-app>
      <preview></preview>
      <screen-video></screen-video>
      <testimonials></testimonials>
      <examples></examples>
      <price></price>
      <faq></faq>
      <news></news>
      <newsletter></newsletter>
    </div>
    <the-footer></the-footer>
    <modal></modal>
    <modal-video></modal-video>
  </div>
</template>

<script>
// import Banner from './components/Banner.vue'
import Hero from './components/Hero.vue'
import ShowcaseSite from './components/ShowcaseSite.vue'
import ShowcaseApp from './components/ShowcaseApp.vue'
import Preview from './components/Preview.vue'
import ScreenVideo from './components/Video.vue'
import Faq from './components/Faq.vue'
import Testimonials from './components/Testimonials.vue'
import Examples from './components/Examples.vue'
import Price from './components/Price.vue'
import News from './components/News.vue'
import Newsletter from './components/Newsletter.vue'
import TheFooter from './components/Footer.vue'
import Modal from './components/Modal.vue'
import ModalVideo from './components/ModalVideo.vue'

export default {
  name: 'App',
  methods: {
    track() {
      this.$ga.page('/')
    }
  },
  components: {
    // Banner,
    Hero,
    ShowcaseSite,
    ShowcaseApp,
    Preview,
    ScreenVideo,
    Faq,
    Price,
    Testimonials,
    Examples,
    News,
    Newsletter,
    TheFooter,
    Modal,
    ModalVideo
  }
}
</script>

<style src="@/scss/styles.scss" lang="scss" />
