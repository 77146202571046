<template>
  <button class="button button-action" @click="openModal" modal="access">
    <span class="button-text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'ButtonPrimary',
  data() {
    return {
      text: 'Get Started for Free',
      type: 'access'
    }
  },
  methods: {
    openModal() {
      this.$events.$emit('open-modal', this.type)
    }
  }
}
</script>
