<template>
  <span class="icon">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        class="icon-twitter"
        fill="#000"
        fill-rule="evenodd"
        d="M19.944 7.987c.013.175.013.35.013.525C19.957 13.85 15.833 20 8.294 20c-2.322 0-4.48-.663-6.294-1.812.33.037.647.05.99.05 1.916 0 3.68-.638 5.089-1.725-1.802-.038-3.313-1.2-3.833-2.8.254.037.508.062.774.062.368 0 .736-.05 1.079-.137-1.878-.376-3.287-2-3.287-3.963v-.05c.546.3 1.18.488 1.853.512a4.02 4.02 0 0 1-1.827-3.362c0-.75.203-1.438.558-2.038a11.71 11.71 0 0 0 8.452 4.225 4.492 4.492 0 0 1-.102-.924c0-2.226 1.828-4.038 4.1-4.038 1.18 0 2.245.487 2.994 1.275a8.145 8.145 0 0 0 2.602-.975 4.038 4.038 0 0 1-1.802 2.225A8.316 8.316 0 0 0 22 5.9a8.74 8.74 0 0 1-2.056 2.087z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IconTwitter'
}
</script>
