<template>
  <span class="icon">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        class="icon-medium"
        fill="#000"
        fill-rule="evenodd"
        d="M4.37 7.238a.78.78 0 0 0-.253-.654l-1.865-2.25V4H8.05l4.482 9.828L16.471 4H22v.335l-1.596 1.53a.466.466 0 0 0-.178.446v11.247a.466.466 0 0 0 .178.447l1.559 1.53v.334h-7.844v-.335l1.617-1.567c.157-.157.157-.206.157-.446V8.432L11.398 19.84h-.607L5.564 8.432v7.646c-.045.322.062.645.29.876l2.1 2.547v.335H2v-.33l2.1-2.552c.224-.231.327-.558.27-.876v-8.84z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IconTwitter'
}
</script>
