<template>
  <section class="layout-section">
    <div class="price">
      <header class="layout-intro">
        <h1>{{ heading }}</h1>
        <p>
          Start your
          <strong>14 day FREE trial</strong> now and stay on the Early Access price forever.
        </p>
      </header>
      <div class="price-content">
        <div class="price-section">
          <ul class="price-list">
            <li class="price-list-item" v-for="(item, index) in list" :key="index">
              <span class="price-list-tick">
                <icon-tick />
              </span>
              <span class="price-list-text">{{ item }}</span>
            </li>
          </ul>
        </div>

        <div class="price-grid">
          <div v-for="(card, index) in cards" :key="index" class="price-grid-item">
            <div class="card">
              <div class="card-header">
                <h2 class="card-title">{{ card.type }}</h2>
                <span v-if="card.offer" class="card-offer">2 months free</span>
              </div>
              <div class="card-content">
                <span class="card-value">
                  ${{ card.offerPrice }}
                  <span class="card-term">/{{ card.term }}</span>
                </span>
                <span class="card-regular">Regular price ${{ card.regularPrice }}</span>
              </div>
              <div class="card-footer">
                <span class="card-terms">
                  14 day free trial
                  <br />No credit card required
                </span>
                <button-primary></button-primary>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconTick from '../icons/IconTick.vue'
import ButtonPrimary from './ButtonPrimary.vue'

export default {
  name: 'Price',
  data() {
    return {
      heading: 'Early access price. Forever',
      text: 'Price cards here',
      list: [
        'Get online in minutes',
        '14 day FREE trial',
        'Unlimited pages & content',
        'No set up, coding or design',
        'Connect your domain name',
        'Cancel anytime'
      ],
      cards: [
        {
          type: 'Yearly',
          offer: true,
          offerPrice: '90',
          regularPrice: '140',
          term: 'year'
        },
        {
          type: 'Monthly',
          offer: false,
          offerPrice: '9',
          regularPrice: '14',
          term: 'month'
        }
      ]
    }
  },
  components: {
    IconTick,
    ButtonPrimary
  }
}
</script>
