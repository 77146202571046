<template>
  <span class="icon">
    <svg
      version="1.2"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      xml:space="preserve"
    >
      <g id="_x36_4px_boxes">
        <rect fill="none" width="64" height="64" />
      </g>
      <g id="Production">
        <g>
          <path
            fill="#565D64"
            d="M54.6192,30.0284c-0.6446-0.549-16.0234-13.544-36.1896-21.7296c-2.5232-1.0216-5.286,0.675-5.5115,3.2978
			c-1.188,13.0214-1.2249,28.8704,0,42.8136c0.2302,2.6254,2.9935,4.3074,5.5115,3.293
			c20.1662-8.1856,35.545-21.1808,36.1896-21.7306C56.4542,34.4114,56.4606,31.5972,54.6192,30.0284z"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IconPlay'
}
</script>
