<template>
  <section class="layout-section">
    <div class="news">
      <header class="layout-intro">
        <h1>{{ heading }}</h1>
      </header>
      <div class="news-grid">
        <a v-for="(news, index) in newsPosts" :key="index" :href="news.link" class="news-item" target="_blank">
          <img class="news-cover" v-lazy="news.cover" :alt="news.title" />
          <div class="news-box">
            <h2 class="news-title">{{ news.title }}</h2>
            <a class="news-link" :href="news.link" target="_blank">{{ news.button }}</a>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'News',
  data() {
    return {
      heading: 'Find out more',
      newsPosts: [
        {
          title: 'Vibecast partners with London Sound Academy for free DJ websites',
          cover: 'https://static.vibecast.com/web/blog-lsa-partnership.jpg',
          link: 'https://www.londonsoundacademy.com/blog/build-a-dj-website-with-vibecast',
          button: 'Read on London Sound Academy'
        },
        {
          title: 'Why you need an artist website with Jack Barham from Vibecast',
          cover: 'https://static.vibecast.com/web/blog-jackbarham-dt.jpg',
          link:
            'https://datatransmission.co/learn/boost-podcast/why-you-need-an-artist-website-with-jack-barham-vibecast',
          button: 'Listen on Data Transmission'
        },
        {
          title: 'The story behind Vibecast - "a service I wanted as a DJ"',
          cover: 'https://static.vibecast.com/web/blog-jackbarham-vc1.jpg',
          link: 'https://blog.vibecast.com/welcome-to-vibecast-the-website-builder-for-djs-a412fe1e529d',
          button: 'Read on Medium'
        }
      ]
    }
  }
}
</script>
