<template>
  <section class="layout-section">
    <div class="examples">
      <header class="layout-intro layout-intro--two">
        <h1>{{ heading }}</h1>
        <p>{{ sub }}</p>
      </header>
      <div class="examples__items">
        <a
          v-for="(example, index) in examples"
          :key="index"
          class="examples__item"
          :href="'https://' + example.url"
          target="_blank"
        >
          <img class="examples__image" v-lazy="example.image" :alt="example.name" />
          <div class="examples__meta">
            <h4 class="examples__title">{{ example.artist }}</h4>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Examples',
  data() {
    return {
      heading: 'A strong line up',
      sub: 'Checkout other Vibecast websites',
      agileOptions: {
        navButtons: false
      },
      examples: [
        {
          artist: 'Jansons',
          url: 'www.jansonsmusic.com',
          image: 'https://image.vibecast.com/715504b12ab74908944ff6994c1bcf1a.jpg'
        },
        {
          artist: 'Dexter Kane',
          url: 'www.dexterkane.com',
          image: 'https://image.vibecast.com/ed70ff2c31bc40c6b7ede854104c6671.jpg'
        },
        {
          artist: 'GXD',
          url: 'www.gxdmusic.com',
          image: 'https://image.vibecast.com/590c304d5c2d47d29dddb91a6e6c9700.jpg'
        },
        {
          artist: 'The Neighbors',
          url: 'www.theneighborsdj.com',
          image: 'https://static.vibecast.com/web/example/the-neighbors.jpg'
        },
        // {
        //   artist: 'Saul Bliss',
        //   url: 'www.saulbliss.com',
        //   image: 'https://image.vibecast.com/091a9fd4cccb43ffa93cbbe654c226ef.jpg'
        // },
        {
          artist: 'Dom Kane',
          url: 'www.domkane.com',
          image: 'https://image.vibecast.com/9c2cec44fdc6413f96d72e363de1b746.jpg'
        },
        {
          artist: 'Caroline Banx',
          url: 'www.carolinebanx.com',
          image: 'https://image.vibecast.com/bb92a782ce1446529e4c7ef3654035d4.jpg'
        }
      ]
    }
  }
}
</script>
