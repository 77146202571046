<template>
  <section class="layout-section layout-section--slim">
    <div class="newsletter">
      <header class="layout-intro layout-intro--two">
        <h1>Stay in the loop</h1>
      </header>
      <form
        v-if="!message"
        class="newsletter-form"
        @submit.prevent="validateForm('newsletter')"
        data-vv-scope="newsletter"
      >
        <div class="newsletter-input">
          <label v-if="!errors.has('newsletter.email')" class="form-label" for="email">
            Sign up to the Vibecast newsletter
          </label>
          <label v-else class="form-label form-error" for="email">{{ errors.first('newsletter.email') }}</label>
          <input
            v-model="email"
            v-validate="'required|email'"
            type="email"
            id="email"
            name="email"
            class="form-input"
            placeholder="youremail@example.com"
            novalidate
          />
        </div>
        <div class="newsletter-submit">
          <button type="submit" class="button button-action button-wide-mobile">
            <span v-if="!processing" class="button-text">Subscribe</span>
            <div v-if="processing" class="loader action">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </button>
        </div>
      </form>
      <div v-if="message" class="newsletter-message">
        <p>{{ message }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Newsletter',
  data() {
    return {
      email: null,
      processed: false,
      processing: false,
      message: null
    }
  },
  methods: {
    validateForm(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (result) this.submitData()
      })
    },
    submitData() {
      this.processing = true
      axios
        .post(`${process.env.VUE_APP_API}newsletter`, {
          email: this.email
        })
        .then(reponse => {
          this.processing = false
          this.message = reponse.data.message
          this.error = reponse.data.processed
        })
        .catch(error => {
          this.processing = false
          this.message = error.data.message
          this.error = error.data.processed
        })
    }
  }
}
</script>
