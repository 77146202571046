<template>
  <section class="layout-section">
    <div class="preview">
      <header class="layout-intro">
        <h1>Refine your style</h1>
        <p>Customise your website with page, content and colour options.</p>
      </header>
      <div class="preview-grid">
        <div v-for="(theme, index) in themes" :key="index" class="preview-item">
          <div class="showcase-group">
            <div class="showcase-desktop">
              <img class="showcase-image" v-lazy="theme.desktop" :alt="theme.theme + ' desktop'" />
            </div>
            <div class="showcase-mobile">
              <img class="showcase-image" v-lazy="theme.mobile" :alt="theme.mobile + ' mobile'" />
            </div>
          </div>
          <a class="button button-link" :href="theme.link" target="_blank">
            <icon-browser />
            <span>View {{ theme.style }}</span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconBrowser from '../icons/IconBrowser.vue'

export default {
  name: 'Preview',
  data() {
    return {
      themes: [
        {
          style: 'Demo Beyond',
          desktop: 'https://static.vibecast.com/web/showcase-dark-desktop.jpg',
          mobile: 'https://static.vibecast.com/web/showcase-dark-mobile.jpg',
          link: 'https://demobeyond.vibecast.com'
        },
        {
          style: 'Demo Creations',
          desktop: 'https://static.vibecast.com/web/showcase-bright-desktop.jpg',
          mobile: 'https://static.vibecast.com/web/showcase-bright-mobile.jpg',
          link: 'https://democreations.vibecast.com'
        }
      ]
    }
  },
  methods: {
    showImage(image) {
      return `background-image:url('${image}')`
    }
  },
  components: {
    IconBrowser
  }
}
</script>
