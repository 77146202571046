<template>
  <transition v-if="showModal" name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-close" @click="closeModal">
            <icon-close />
          </div>
          <modal-access v-if="modalType === 'access'"></modal-access>
          <modal-social v-if="modalType === 'share'"></modal-social>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconClose from '../icons/IconClose.vue'
import ModalAccess from './ModalAccess.vue'
import ModalSocial from './ModalSocial.vue'

export default {
  name: 'Modal',
  data() {
    return {
      showModal: false,
      modalType: null
    }
  },
  methods: {
    closeModal() {
      this.showModal = false
      this.modalType = null
    }
  },
  mounted() {
    this.$events.$on('open-modal', type => {
      this.showModal = true
      this.modalType = type
    })
  },
  components: {
    IconClose,
    ModalAccess,
    ModalSocial
  }
}
</script>
