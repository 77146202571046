<template>
  <span class="icon">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        class="icon-heart"
        fill="#000"
        fill-rule="evenodd"
        d="M16.665 4C14.713 4 12.882 5.528 12 6.392 11.118 5.528 9.287 4 7.335 4 3.996 4 2 5.938 2 9.183c0 2.66 2.456 4.883 2.544 4.96l6.875 6.623a.84.84 0 0 0 1.162 0l6.864-6.6c.1-.1 2.555-2.327 2.555-4.983C22 5.938 20.004 4 16.665 4z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IconHeart'
}
</script>
