<template>
  <section class="layout-section">
    <div class="faq">
      <header class="layout-intro">
        <h1>{{ heading }}</h1>
      </header>
      <div class="faq-list">
        <div v-for="(faq, index) in faqs" :key="index" class="faq-item" :class="{ open: faq.open }">
          <div class="faq-header" @click="toggleFaq(faq, index)">
            <div class="faq-question">{{ faq.question }}</div>
            <div class="faq-toggle"></div>
          </div>
          <div class="faq-answer" :style="{ height: faq.height }">
            <ul class="faq-answer-list" ref="faqHeight">
              <li v-for="(awnswer, index) in faq.awnswer" :key="index" v-html="awnswer"></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="faq-contact">
        <p class="layout-small">
          If you have further questions, please email <a href="mailto:support@vibecast.com">support@vibecast.com</a> or
          tweet <a href="https://twitter.com/vibecastmusic" target="_blank">@vibecastmusic</a>.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Faq',
  data() {
    return {
      heading: 'Your questions answered',
      faqs: [
        {
          open: false,
          height: 0,
          question: 'Can musicians and other artists use Vibecast?',
          awnswer: [
            'Yes, absolutely. Vibecast is suitable for musicians, producers, bands, record labels, performers and other similar creatives.',
            'Anyone who would like to showcase audio, video, images, tracks, gigs or post news will find Vibecast useful.',
            'During early access, platform development and marketing is focused towards DJs.'
          ]
        },
        {
          open: false,
          height: 0,
          question: 'Do I need any design or coding skills?',
          awnswer: [
            "Not at all. Vibecast's easy to use and intuitive drag and drop interface allows anyone to create a slick looking website in minutes."
          ]
        },
        {
          open: false,
          height: 0,
          question: 'Can I connect my domain name?',
          awnswer: [
            'Yes, you can connect a new or existing domain name to your Vibecast website.',
            "During early access, we'll even help connect your domain name for free."
          ]
        },
        {
          open: false,
          height: 0,
          question: 'Why not use WordPress or other website builders?',
          awnswer: [
            "For general websites, both are great options. However, WordPress and other site builders focus on trying to please everyone. As a DJ you're overloaded with too many options you don't need, at the expense of the ones you do.",
            'Vibecast is a website builder with a focus towards DJs.'
          ]
        },
        {
          open: false,
          height: 0,
          question: 'What if I need more time after the free trial?',
          awnswer: [
            'No problem. We understand that 14 days is not always enough time, or you might have been busy with other things.',
            'Send an email to <a href="mailto:support@vibecast.com">support@vibecast.com</a> with your request, and we\'ll happily extend your free trial period.'
          ]
        }
      ]
    }
  },
  methods: {
    toggleFaq(faq, index) {
      const height = this.$refs.faqHeight[index].offsetHeight
      if (!faq.open) {
        this.closeFaq()
        faq.open = true
        faq.height = `${height}px`
      } else {
        faq.open = false
        faq.height = 0
      }
    },
    handleWindowResize() {
      setTimeout(() => {
        this.closeFaq()
      }, 1000)
    },
    closeFaq() {
      this.faqs = this.faqs.map(faq => {
        faq.height = 0
        faq.open = false
        return faq
      })
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize)
  }
}
</script>
