<template>
  <a @click.prevent="openVideo" class="button button-link" href="#">
    <icon-play />
    <span>Watch the video</span>
  </a>
</template>

<script>
import IconPlay from '../icons/IconPlay.vue'

export default {
  name: 'ButtonSecondary',
  data() {
    return {
      type: 'video'
    }
  },
  methods: {
    openVideo() {
      this.$events.$emit('open-video')
    }
  },
  components: {
    IconPlay
  }
}
</script>
