<template>
  <section class="layout-section">
    <div class="showcase">
      <div class="showcase-info showcase-site">
        <h1 class="showcase-title showcase-title--web">Your website is ready</h1>
        <p class="showcase-text">
          Showcase your talent on a sleek, modern and customisable website. Designed for DJs, created in minutes.
        </p>
        <a class="button button-link" href="https://djdemo.vibecast.com" target="_blank">
          <icon-browser />
          <span>{{ text }}</span>
        </a>
      </div>
      <div class="showcase-visual">
        <div class="showcase-group">
          <div class="showcase-desktop">
            <img class="showcase-image" v-lazy="desktop" alt="DJ Demo desktop" />
          </div>
          <div class="showcase-mobile">
            <img class="showcase-image" v-lazy="mobile" alt="DJ Demo mobile" />
          </div>
        </div>
        <a class="button button-link" href="https://djdemo.vibecast.com" target="_blank">
          <icon-browser />
          <span>{{ text }}</span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import IconBrowser from '../icons/IconBrowser.vue'

export default {
  name: 'ShowcaseSite',
  data() {
    return {
      text: 'View DJ Demo',
      desktop: 'https://static.vibecast.com/web/showcase-light-desktop.jpg',
      mobile: 'https://static.vibecast.com/web/showcase-light-mobile.jpg'
    }
  },
  components: {
    IconBrowser
  }
}
</script>
