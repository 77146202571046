<template>
  <section class="hero">
    <img class="hero-image" v-lazy="image" alt="DJ at a party" />
    <top-bar></top-bar>
    <div class="hero-filter">
      <div class="hero-content">
        <h1 class="hero-title" v-html="title"></h1>
        <h2 class="hero-sub-title">{{ subTitle }}</h2>
        <div class="hero-cta">
          <button-primary class="hero-button-register"></button-primary>
          <button-ghost class="hero-button-video"></button-ghost>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TopBar from './TopBar.vue'
import ButtonPrimary from './ButtonPrimary.vue'
import ButtonGhost from './ButtonGhost.vue'

export default {
  name: 'Hero',
  data() {
    return {
      image: 'https://static.vibecast.com/web/hero-v1.jpg',
      title: 'Play your best side',
      subTitle: 'The website builder for DJs'
    }
  },
  components: {
    TopBar,
    ButtonPrimary,
    ButtonGhost
  }
}
</script>
