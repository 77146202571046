<template>
  <ul class="social">
    <li>
      <a class="social-instagram" href="https://www.instagram.com/vibecastmusic" target="_blank"><icon-instagram /></a>
    </li>
    <li>
      <a class="social-twitter" href="https://twitter.com/vibecastmusic" target="_blank"><icon-twitter /></a>
    </li>
    <li>
      <a class="social-facebook" href="https://www.facebook.com/vibecasthq" target="_blank"><icon-facebook /></a>
    </li>
    <li>
      <a class="social-medium" href="https://blog.vibecast.com" target="_blank"><icon-medium /></a>
    </li>
  </ul>
</template>

<script>
import IconInstagram from '../icons/IconInstagram.vue'
import IconFacebook from '../icons/IconFacebook.vue'
import IconTwitter from '../icons/IconTwitter.vue'
import IconMedium from '../icons/IconMedium.vue'

export default {
  name: 'Social',
  components: {
    IconInstagram,
    IconFacebook,
    IconTwitter,
    IconMedium
  }
}
</script>
