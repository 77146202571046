<template>
  <span class="icon">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        class="icon-browser"
        fill="#000"
        fill-rule="evenodd"
        d="M12.01 2C6.482 2 2 6.48 2 12.005 2 17.53 6.482 22.01 12.01 22.01c5.525 0 10-4.479 10-10.005C22.01 6.479 17.535 2 12.01 2zm0 17.475a7.472 7.472 0 0 1-7.475-7.47 7.472 7.472 0 0 1 7.474-7.47 7.467 7.467 0 0 1 7.467 7.47 7.467 7.467 0 0 1-7.467 7.47z"
      />
      <path
        class="icon-browser"
        fill="#000"
        fill-rule="evenodd"
        d="M15.148 8.012c-4.14 1.452-8.016 3.994-8.182 4.105-.549.367-.284 1.222.37 1.222h3.335v3.335c0 .664.86.914 1.222.37.11-.166 2.653-4.042 4.105-8.182a.668.668 0 0 0-.85-.85z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IconBrowser'
}
</script>
