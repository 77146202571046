<template>
  <form @submit.prevent="validateForm('access')" data-vv-scope="access">
    <header class="modal-header">
      <h1>Get Started</h1>
    </header>
    <div class="modal-content">
      <p v-if="referrer !== 'QBOPdA'">
        Start your
        <strong>14 day FREE trial</strong> and get your website online in minutes.
      </p>
      <p v-if="referrer === 'QBOPdA'">
        Start your exclusive DJ Growth Lab
        <strong>60 day FREE trial</strong> and get your website online in minutes.
      </p>
    </div>
    <div class="form-group">
      <label v-if="!errors.has('access.email')" class="form-label" for="email">Your email address</label>
      <label v-else class="form-label form-error" for="email">{{ errors.first('access.email') }}</label>
      <input
        v-model="email"
        v-validate="'required|email'"
        type="email"
        id="email"
        name="email"
        class="form-input"
        placeholder="someone@example.com"
        novalidate
      />
    </div>
    <div class="form-submit">
      <button type="submit" class="button button-action button-wide-mobile">
        <span v-if="!processing" class="button-text">Send Activation Link</span>
        <div v-if="processing" class="loader action">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </button>
    </div>
  </form>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ModalAccess',
  data() {
    return {
      email: null,
      referrer: null,
      lmCode: null,
      processing: false
    }
  },
  created: function() {
    this.getReferrer()
    this.getLinkMinkCode()
  },
  methods: {
    getReferrer() {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get('ref')) this.referrer = urlParams.get('ref')
      if (urlParams.get('lmref')) this.referrer = urlParams.get('lmref')
    },
    getLinkMinkCode() {
      if (lmFinished() !== undefined) {
        this.lmCode = lmFinished()
      }
    },
    validateForm(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (result) this.submitData()
      })
    },
    submitData() {
      this.processing = true
      axios
        .post(`${process.env.VUE_APP_API}preregister`, {
          email: this.email,
          referrer: this.referrer,
          tracking_code: this.lmCode
        })
        .then(() => {
          this.processing = false
          this.$parent.modalType = 'share'
        })
        .catch(() => {
          this.processing = false
        })
    }
  }
}
</script>
