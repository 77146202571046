// IE11 Polyfil fix
// eslint-disable-next-line
import Es6Promise from 'es6-promise/auto'

import Vue from 'vue'
import App from './App.vue'
import VeeValidate from 'vee-validate'
import VueLazyload from 'vue-lazyload'

const veeConfig = {
  events: 'blur',
  validity: false,
  fieldsBagName: 'fieldsBag',
  dictionary: {
    en: {
      custom: {
        email: {
          required: 'Email address is required',
          email: 'Please use a valid email address'
        }
      }
    }
  }
}

Vue.use(VeeValidate, veeConfig)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
})

Vue.config.productionTip = false
Vue.prototype.$events = new Vue()

new Vue({
  render: h => h(App)
}).$mount('#app')
