<template>
  <span class="icon">
    <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <path
        class="icon-arrow-right"
        fill="#000"
        fill-rule="evenodd"
        d="M60 30.063c-.504-.594-13.203-14.631-27.527-23.618C30.525 5.223 28 6.64 28 8.953V20.48c-9.14 1.399-18.348 3.41-19.813 4.211C5.694 26.05 4 28.341 4 32.02c0 3.687 1.693 5.97 4.188 7.332 1.464.8 10.671 2.814 19.812 4.21v11.465c0 2.536 2.957 3.868 4.834 2.242C47 48.277 59.5 34.49 60 33.902a2.973 2.973 0 0 0 0-3.84z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IconArrowRight'
}
</script>
