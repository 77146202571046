<template>
  <section class="layout-section">
    <div class="showcase showcase-reverse">
      <div class="showcase-info showcase-admin">
        <h1 class="showcase-title">Effortless admin</h1>
        <p class="showcase-text">
          Import from Soundcloud, Mixcloud, YouTube and more. Add photos, gigs, releases and share your latest news on a
          simple Drag & Drop builder.
        </p>
        <button-secondary />
      </div>
      <div class="showcase-visual">
        <div class="showcase-app">
          <img class="showcase-image" v-lazy="image" alt="Vibecast admin dashboard" />
        </div>
        <button-secondary />
      </div>
    </div>
  </section>
</template>

<script>
import ButtonSecondary from './ButtonSecondary.vue'

export default {
  name: 'ShowcaseApp',
  data() {
    return {
      image: 'https://static.vibecast.com/web/showcase-app.jpg'
    }
  },
  components: {
    ButtonSecondary
  }
}
</script>
