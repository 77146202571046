<template>
  <section class="layout-section">
    <div class="video">
      <header class="layout-intro video-header">
        <h1>See it for yourself</h1>
      </header>
      <div class="video-player">
        <div class="video-wrapper">
          <iframe :src="videoEmbed()" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Video',
  methods: {
    videoEmbed() {
      return '//youtube.com/embed/VufvKNVEAIo?showinfo=0&rel=0?mode=opaque&amp&rel=0&amp&autohide=1&amp&showinfo=0&wmode=transparent'
    }
  }
}
</script>
