<template>
  <div class="topbar">
    <h1 class="topbar-logo">Vibecast</h1>
    <nav class="topbar-menu">
      <a href="https://blog.vibecast.com">Blog</a>
      <a href="https://app.vibecast.com/login">Log in</a>
      <button-primary></button-primary>
    </nav>
  </div>
</template>

<script>
import ButtonPrimary from './ButtonPrimary.vue'

export default {
  name: 'TopBar',
  components: {
    ButtonPrimary
  }
}
</script>
