<template>
  <section class="layout-section">
    <div class="testimonials">
      <header class="layout-intro">
        <h1>
          {{ heading }} <span class="layout-intro-icon"><icon-heart /></span>
        </h1>
      </header>
      <div class="testimonials__content">
        <vue-agile ref="carousel" :options="agileOptions">
          <div v-for="(quote, index) in quotes" :key="index" class="slide">
            <div class="testimonials__wrap">
              <span v-html="quote.comment" class="testimonials__quote"></span>
              <ul class="testimonials__meta">
                <li class="testimonials__person">
                  <a :href="'https://' + quote.website" target="_blank">{{ quote.person }}</a>
                </li>
                <li class="testimonials__website">
                  <a :href="quote.tweet" target="_blank">View tweet</a>
                </li>
              </ul>
            </div>
          </div>
        </vue-agile>
        <button @click="$refs.carousel.goToPrev()" class="testimonials__button testimonials__button--prev">
          <icon-arrow-left />
        </button>
        <button @click="$refs.carousel.goToNext()" class="testimonials__button testimonials__button--next">
          <icon-arrow-right />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { VueAgile } from 'vue-agile'
import IconHeart from '../icons/IconHeart.vue'
import IconArrowRight from '../icons/IconArrowRight.vue'
import IconArrowLeft from '../icons/IconArrowLeft.vue'

export default {
  name: 'Testimonial',
  data() {
    return {
      heading: 'So much love',
      agileOptions: {
        navButtons: false
      },
      quotes: [
        {
          person: 'Darper',
          tweet: 'https://twitter.com/darpermusic/status/1159076024203862016',
          website: 'www.darpermusic.com',
          comment:
            'DJs, I highly recommend <a href="https://twitter.com/VibecastMusic" target="_blank">@VibecastMusic</a> for your website. I\'ve been using it for a while, impressed with how easy it is to set up and chuffed to bits with how my site looks <a href="https://www.darpermusic.com" target="_blank">www.darpermusic.com</a>. Take a look, you\'ll save a lot of time <a href="https://www.vibecast.com">www.vibecast.com</a>.'
        },
        {
          person: 'Jaaku',
          tweet: 'https://twitter.com/Jaaku_Music/status/1170726499726610432',
          website: 'www.jaaku.co.uk',
          comment:
            'Producers, I recently made a website using <a href="https://twitter.com/VibecastMusic" target="_blank">@VibecastMusic</a> and I want recommend the platform. Easy to use, great support and an amazing clean professional looking website. Check mine out here <a href="https://www.jaaku.co.uk" target="_blank">www.jaaku.co.uk</a>.'
        },
        {
          person: 'Saul Bliss',
          tweet: 'https://twitter.com/Djsaulbliss/status/1156549093344153601',
          website: 'www.saulbliss.com',
          comment:
            'I highly recommend <a href="https://twitter.com/VibecastMusic" target="_blank">@VibecastMusic</a> for your DJ website. My ting <a href="https://www.saulbliss.com" target="_blank">www.saulbliss.com</a> was super easy to set up, love how simple it is to use, all my content is in one place, and it\'s helped me get more gigs. DJs, you should defo check the powerful bad boy <a href="https://www.vibecast.com">www.vibecast.com</a>.'
        },
        {
          person: 'Dexter Kane',
          tweet: 'https://twitter.com/DexterKane/status/1157227545139585024',
          website: 'www.dexterkane.com',
          comment:
            'My pals over <a href="https://twitter.com/VibecastMusic" target="_blank">@VibecastMusic</a> have come up with a great solution for artists to build their own website in a way that no social media platform can offer. I\'m really happy with mine, it was easy as anything to set up. Check <a href="https://www.dexterkane.com" target="_blank">www.dexterkane.com</a> for a idea of what it can do.'
        },
        {
          person: 'Forsyth',
          tweet: 'https://twitter.com/forsythorfalse/status/1156616346760646657',
          website: 'www.forsythmusic.com',
          comment:
            'I\'ve been using <a href="https://www.vibecast.com">www.vibecast.com</a> for my website and to keep my fans updated with all things Forsyth <a href="https://www.forsythmusic.com" target="_blank">www.forsythmusic.com</a>. It\'s easy to use and convey who you are as an artist through the layout of the site (plus it looks dope). I highly recommended <a href="https://twitter.com/VibecastMusic" target="_blank">@VibecastMusic</a>.'
        },
        {
          person: 'Chuck Woodward',
          tweet: 'https://twitter.com/chuck_woodward/status/1157346524650778624',
          website: 'chuckwoodward.vibecast.com',
          comment:
            'I\'ve been using <a href="https://twitter.com/VibecastMusic" target="_blank">@VibecastMusic</a> for about a year now for my website. It is a fantastic solution to what was once a daunting task. Highly recommend for any DJ or producer! Check out my website <a href="https://chuckwoodward.vibecast.com" target="_blank">www.chuckwoodward.ca</a>.'
        }
      ]
    }
  },
  // mounted() {
  //   this.$refs.carousel.reload()
  // },
  components: {
    VueAgile,
    IconHeart,
    IconArrowRight,
    IconArrowLeft
  }
}
</script>
