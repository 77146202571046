<template>
  <div>
    <header class="modal-header">
      <h1>Almost Done</h1>
    </header>
    <div class="modal-content">
      <p>Your Early Access invite will be with you shortly. Check your email, you're almost done.</p>
      <p>
        <span class="message-negative">Sometimes emails go to junk or 'other' folders.</span>
      </p>
    </div>
    <div class="share-wrap">
      <a :href="twitter" target="_blank" class="share-item share-twitter">
        <div class="share-content">
          <icon-twitter />
          <span>Share on Twitter</span>
        </div>
      </a>
      <a :href="facebook" target="_blank" class="share-item share-facebook">
        <div class="share-content">
          <icon-facebook />
          <span>Share on Facebook</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import IconFacebook from '../icons/IconFacebook.vue'
import IconTwitter from '../icons/IconTwitter.vue'

export default {
  name: 'ModalSocial',
  data() {
    return {
      twitter:
        'https://twitter.com/intent/tweet?text=I%27ve%20signed%20up%20to%20Vibecast,%20the%20website%20builder%20designed%20for%20DJs&url=https%3A%2F%2Fwww.vibecast.com&via=VibecastMusic',
      facebook: 'http://www.facebook.com/share.php?u=https://www.vibecast.com'
    }
  },
  components: {
    IconFacebook,
    IconTwitter
  }
}
</script>
