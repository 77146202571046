<template>
  <span class="icon">
    <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <path
        class="icon-arrow-left"
        fill="#000"
        fill-rule="evenodd"
        d="M55.813 24.648c-1.465-.8-10.672-2.814-19.813-4.21V9.012v-.039c0-2.536-2.957-3.868-4.834-2.242C17 15.723 4.5 29.51 4 30.098a2.973 2.973 0 0 0 0 3.84c.504.593 13.203 14.61 27.527 23.597C33.475 58.757 36 57.34 36 55.027V43.52c9.14-1.399 18.348-3.41 19.813-4.211 2.494-1.358 4.128-3.65 4.128-7.329 0-3.687-1.634-5.97-4.129-7.332z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IconArrowLeft'
}
</script>
