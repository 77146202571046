<template>
  <span class="icon">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        class="icon-tick"
        fill="#000"
        fill-rule="nonevenoddzero"
        d="M2.23 12.762a.831.831 0 0 1-.23-.539c0-.154.077-.385.23-.538l1.078-1.077a.744.744 0 0 1 1.077 0l.077.077 4.23 4.538a.372.372 0 0 0 .539 0L19.538 4.531h.077a.744.744 0 0 1 1.077 0l1.077 1.077a.744.744 0 0 1 0 1.077L9.462 19.454a.698.698 0 0 1-.539.23.698.698 0 0 1-.538-.23l-6-6.462-.154-.23z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IconTick'
}
</script>
