<template>
  <transition v-if="showVideoModal" name="modal">
    <div class="modal-mask modal-mask-darker">
      <div class="modal-video">
        <div class="modal-close" @click="closeModal">
          <icon-close />
        </div>
        <div class="video-wrapper">
          <iframe :src="videoEmbed()" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconClose from '../icons/IconClose.vue'

export default {
  name: 'ModalVideo',
  data() {
    return {
      showVideoModal: false
    }
  },
  methods: {
    closeModal() {
      this.showVideoModal = false
    },
    videoEmbed() {
      return '//youtube.com/embed/VufvKNVEAIo?showinfo=0&autoplay=1&rel=0'
    }
  },
  mounted() {
    this.$events.$on('open-video', () => {
      this.showVideoModal = true
    })
  },
  components: {
    IconClose
  }
}
</script>
