<template>
  <button class="button button-ghost button-ghost--white" @click="openVideo()" modal="access">
    <icon-play />
    <span class="button-text">{{ text }}</span>
  </button>
</template>

<script>
import IconPlay from '../icons/IconPlay.vue'

export default {
  name: 'ButtonPrimary',
  data() {
    return {
      text: 'Watch the Video',
      type: 'access'
    }
  },
  methods: {
    openVideo() {
      this.$events.$emit('open-video')
    }
  },
  components: {
    IconPlay
  }
}
</script>
