<template>
  <span class="icon">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        class="icon-close"
        fill="#000"
        fill-rule="evenodd"
        d="M14.877 12.32a.441.441 0 0 1 0-.64l6.849-6.849a.987.987 0 0 0 .274-.64.987.987 0 0 0-.274-.638l-1.279-1.279A.987.987 0 0 0 19.808 2a.83.83 0 0 0-.64.274l-6.848 6.85a.441.441 0 0 1-.64 0l-6.849-6.85A.987.987 0 0 0 4.191 2a.987.987 0 0 0-.638.274L2.274 3.553A.987.987 0 0 0 2 4.192c0 .182.091.456.274.64l6.85 6.848a.441.441 0 0 1 0 .64l-6.85 6.849a.987.987 0 0 0-.274.64c0 .182.091.456.274.638l1.279 1.279a.987.987 0 0 0 .639.274.987.987 0 0 0 .64-.274l6.848-6.85a.441.441 0 0 1 .64 0l6.849 6.85a.987.987 0 0 0 .64.274.987.987 0 0 0 .638-.274l1.279-1.279a.987.987 0 0 0 .274-.639.987.987 0 0 0-.274-.64l-6.85-6.848z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IconClose'
}
</script>
