<template>
  <span class="icon">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        class="icon-facebook"
        fill="#000"
        fill-rule="evenodd"
        d="M9.905 22v-8.945H7V9.5h2.905V6.7c0-3.044 1.803-4.7 4.436-4.7 1.261 0 2.345.098 2.659.14v3.18h-1.826c-1.432 0-1.708.703-1.708 1.73V9.5h3.231l-.443 3.555h-2.788V22"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IconFacebook'
}
</script>
