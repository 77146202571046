<template>
  <footer class="footer">
    <div class="footer-inner">
      <social></social>
      <div class="footer-made">
        <icon-heart />
        <span v-html="made"></span>
      </div>
      <ul class="footer-legal">
        <li v-html="legal"></li>
        <li>
          <a :href="'mailto:' + email">{{ email }}</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import IconHeart from '../icons/IconHeart.vue'
import Social from './Social.vue'

export default {
  name: 'Footer',
  data() {
    return {
      made: 'Handmade in Hackney by <a href="https://twitter.com/jackbarham" target="_blank">Jack Barham</a>',
      legal: 'Copyright &copy; 2020 &mdash; Vibecast Ltd',
      email: 'info@vibecast.com'
    }
  },
  components: {
    IconHeart,
    Social
  }
}
</script>
